import { ReactElement } from 'react'
import './App.css'

const App = (): ReactElement => {
  return (
    <div className="App">
      <h1 className="loading">
        <span>r</span>
        <span>u</span>
        <span>m</span>
        <span>m</span>
        <span>e</span>
        <span>l</span>
        <span>o</span>
        <span>n</span>
        <span>p</span>
        <span>.</span>
        <span>c</span>
        <span>o</span>
        <span>m</span>
      </h1>

      <section>
        <h2>Name</h2>
        <p>Kazuya Takeshima</p>
      </section>

      <section>
        <h2>Blog</h2>
        <p>
          <a href="https://rummelonp.hatenablog.com" rel="me external" target="blank">
            rummelonp.hatenablog.com
          </a>
        </p>
      </section>

      <section>
        <h2>Mastodon</h2>
        <p>
          <a href="https://mstdn.jp/@rummelonp" rel="me external" target="blank">
            @rummelonp@mstdn.jp
          </a>
        </p>
      </section>

      <section>
        <h2>Twitter</h2>
        <p>
          <a href="https://twitter.com/rummelonp" rel="me external" target="blank">
            @rummelonp
          </a>
        </p>
      </section>

      <section>
        <h2>GitHub</h2>
        <p>
          <a href="https://github.com/rummelonp" rel="me external" target="blank">
            @rummelonp
          </a>
        </p>
      </section>
    </div>
  )
}

export default App
